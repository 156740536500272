(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/trend-indicator/assets/javascripts/trend-indicator.js') >= 0) return;  svs.modules.push('/components/tipsen/trend-indicator/assets/javascripts/trend-indicator.js');
"use strict";


const {
  useRef,
  useEffect,
  useState
} = React;
const TREND_RESET_TIMEOUT = 7 * 1000;
const blockName = 'pg_trend_indicator';
const Trends = {
  UP: 'up',
  DOWN: 'down'
};
const TrendIndicator = _ref => {
  let {
    value,
    id,
    suffix = ''
  } = _ref;
  const [trendClassName, setTrendClassName] = useState('');
  const prevValueRef = useRef(value);
  const prevIdRef = useRef(id);
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (prevIdRef.current !== id) {
      prevIdRef.current = id;
      prevValueRef.current = value;
      setTrendClassName('');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      return;
    }
    if (value > prevValueRef.current) {
      setTrendClassName("".concat(blockName, "--").concat(Trends.UP));
    } else if (value < prevValueRef.current) {
      setTrendClassName("".concat(blockName, "--").concat(Trends.DOWN));
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setTrendClassName('');
    }, TREND_RESET_TIMEOUT);
    prevValueRef.current = value;
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [value, id]);
  return React.createElement("div", {
    className: "".concat(blockName, " ").concat(trendClassName).trim()
  }, value, suffix);
};
setGlobal('svs.components.tipsen.trendIndicator', {
  TrendIndicator
});

 })(window);